import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';
import { Apollo, gql } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { SnackbarService } from 'services/snackbar.service';
import { AgentCompany } from 'src/app/common-models/agent-company';
import {
  HandoverProtocolCreation,
  HandoverProtocol,
  SignedAndCompletedHandoverProtocol,
  SigneeSignatureResult,
} from './handover-protocol.model';
import { mapSignatureDataForGraphQL } from 'src/app/helper/mapSignatureDataForGraphQL';
import { EmailPreviewBodyDto } from '@types';

@Injectable({ providedIn: 'root' })
export class HandoverProtocolApiFacade {
  constructor(
    private readonly _apollo: Apollo,
    private readonly _snackbarService: SnackbarService,
  ) { }

  saveHandoverProtocol$(data: HandoverProtocolCreation): Observable<HandoverProtocol> {
    return this._apollo.mutate({
      mutation: gql`
        mutation SaveHandoverProtocol($hop: HandoverProtocolInput!) {
          handoverProtocol(hop: $hop) {
            id
            state
            objectId
            lastVisited
            transfereeType
            transferees
            businessTransferee
            transferorType
            transferors
            businessTransferor
            protocolType
            propertyInformation {
              areaInformation
              typeInformation
              rentalUnit
              rentalUnitFloor
              rentalNumberTransferor
              rentalNumberTransferee
              rentalDate
              moveOutDate
            }
            handoverRooms {
              paintedSurface
              ingrainWallpaper
              individualRoomHandover
              livingRoom {
                isAvailable
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
              kitchen {
                isAvailable
                freeFromDefects
                builtInKitchen
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
              bedroom {
                isAvailable
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
              bathroom {
                isAvailable
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
              toilet {
                isAvailable
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
              storageRoom {
                isAvailable
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
              balcony {
                isAvailable
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
              terrace {
                isAvailable
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
              garden {
                isAvailable
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
              loggia {
                isAvailable
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
              houseEntrance {
                isAvailable
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
              stairwell {
                isAvailable
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
              cellar {
                isAvailable
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
              parkingSlot {
                isAvailable
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                }
                order
              }
              otherRooms {
                title
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
            }
            propertyCondition {
              asAgreed
              notes
              images {
                id
                storageUrl
                type
                description
              }
            }
            installations {
              available
              notes
              stayAsIs
              removalDate
              otherConditionsNotes
              images {
                id
                storageUrl
                type
                description
              }
            }
            conversions {
              available
              notes
              stayAsIs
              removalDate
              otherConditionsNotes
              images {
                id
                storageUrl
                type
                description
              }
            }
            meters {
              type
              number
              reading
              readingDate
              notes
              images {
                id
                storageUrl
                type
                description
              }
            }
            keys {
              received
              notes
              images {
                id
                storageUrl
                type
                description
              }
            }
            operatorRegistration {
              doEvnRegistration
              electricity {
                doRegister
                startDate
                provider
                contractPartnerId
              }
              gas {
                doRegister
                startDate
                provider
                contractPartnerId
              }
              additionalServices {
                tvAndInternet
                photovoltaics
                insurances
                insuranceOffer {
                  wefox
                  contractPartnerId
                }
                rentControlPackage
              }
            }
            misc {
              handoverDate
              notes
              sendCustomerPortalLink
              images {
                id
                storageUrl
                type
                description
              }
            }
            otherAgreements {
              specialFloorTreatment
              continuosDescaling
              repairmentInfo
              gardenCare
              landlordAccess
              watering
              wasteInformation
              buildingInspection
              contractFurniture
              firstMoveIn
              wallWorks {
                roomNotes
              }
              cleaning {
                specialDirt
                bathDescaling
                jointing
                showerCabin
                showerSealing
                siliconeJoint
                floorCleaning
                balconyCleaning
              }
            }
            deposit {
              received
              amount
              paymentMethod
              notes
            }
            emails
            documents {
              id
              storageUrl
              type
              description
              title
              documentFrom
              state
            }
            agent {
              id
              email
              isRemax
              themeConfig
              firstName
              lastName
              picture
              officeName
              officeAddress
              legalEntity
              phone
            }
          }
        }
      `,
      variables: { hop: data },
    }).pipe(
      first(),
      map((result: any) => ({
        ...result.data.handoverProtocol,
        lastVisited: new Date(result.data.handoverProtocol.lastVisited),
      })),
    );
  }

  deleteHandoverProtocol$(hopId: string): Observable<Boolean> {
    return this._apollo.mutate({
      mutation: gql`
        mutation DeleteHandoverProtocol($hopId: String!) {
          deleteHandoverProtocol(hopId: $hopId)
        }
      `,
      variables: {
        hopId,
      },
    }).pipe(
      map((result: any) => result.data.deleteHandoverProtocol)
    );
  }

  loadHandoverProtocolDetails$(objectId: string, hopId: string): Observable<HandoverProtocol> {
    return this._apollo.query({
      query: gql`
        query getHandoverProtocol($objectId: String!, $hopId: String!) {
          handoverProtocol(objectId: $objectId, hopId: $hopId) {
            id
            objectId
            state
            lastVisited
            signatureData {
              transferors {
                type
                signatureAsSvg
                signatureText
                present
                date
                location
              }
              signedDocumentUrl
            }
            protocolType
            propertyInformation {
              areaInformation
              typeInformation
              rentalUnitFloor
              rentalUnit
              rentalNumberTransferor
              rentalNumberTransferee
              rentalDate
              moveOutDate
            }
            handoverRooms {
              paintedSurface
              ingrainWallpaper
              individualRoomHandover
              livingRoom {
                isAvailable
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
              kitchen {
                isAvailable
                freeFromDefects
                builtInKitchen
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
              bedroom {
                isAvailable
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
              bathroom {
                isAvailable
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
              toilet {
                isAvailable
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
              storageRoom {
                isAvailable
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
              balcony {
                isAvailable
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
              terrace {
                isAvailable
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
              garden {
                isAvailable
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
              loggia {
                isAvailable
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
              houseEntrance {
                isAvailable
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
              stairwell {
                isAvailable
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
              cellar {
                isAvailable
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
              parkingSlot {
                isAvailable
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                }
                order
              }
              otherRooms {
                title
                freeFromDefects
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
                order
              }
            }
            propertyCondition {
              asAgreed
              notes
              images {
                id
                storageUrl
                type
                description
              }
            }
            installations {
              available
              notes
              stayAsIs
              removalDate
              otherConditionsNotes
              images {
                id
                storageUrl
                type
                description
              }
            }
            conversions {
              available
              notes
              stayAsIs
              removalDate
              otherConditionsNotes
              images {
                id
                storageUrl
                type
                description
              }
            }
            meters {
              type
              number
              reading
              readingDate
              notes
              images {
                id
                storageUrl
                type
                description
              }
            }
            transferorType
            transferors
            transferorsData {
              id
              birthdate
              isConsumer
              title
              firstname
              lastname
              nationality
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                  longitude
                  latitude
                }
                phone
                email
              }
              IDImages {
                id
                storageUrl
                type
                description
              }
            }
            businessTransferor
            businessTransferorData {
              id
              companyNumber
              companyRegister
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                }
                phone
                email
              }
              customers {
                id
                birthdate
                title
                firstname
                lastname
                nationality
                contact {
                  address {
                    country
                    city
                    zipCode
                    streetName
                    buildingNumber
                    doorNumber
                    stairway
                    longitude
                    latitude
                  }
                  phone
                  email
                }
                IDImages {
                  id
                  storageUrl
                  type
                  description
                }
              }
            }

            transfereeType
            transferees
            transfereesData {
              id
              birthdate
              isConsumer
              title
              firstname
              lastname
              nationality
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                  longitude
                  latitude
                }
                phone
                email
              }
              IDImages {
                id
                storageUrl
                type
                description
              }
            }
            businessTransferee
            businessTransfereeData {
              id
              companyNumber
              companyRegister
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                }
                phone
                email
              }
              customers {
                id
                birthdate
                title
                firstname
                lastname
                nationality
                contact {
                  address {
                    country
                    city
                    zipCode
                    streetName
                    buildingNumber
                    doorNumber
                    stairway
                    longitude
                    latitude
                  }
                  phone
                  email
                }
                IDImages {
                  id
                  storageUrl
                  type
                  description
                }
              }
            }
            operatorRegistration {
              doEvnRegistration
              electricity {
                doRegister
                startDate
                provider
                contractPartnerId
              }
              gas {
                doRegister
                startDate
                provider
                contractPartnerId
              }
              additionalServices {
                tvAndInternet
                photovoltaics
                insurances
                insuranceOffer {
                  wefox
                  contractPartnerId
                }
                rentControlPackage
              }
            }
            misc {
              handoverDate
              notes
              sendCustomerPortalLink
              images {
                id
                storageUrl
                type
                description
              }
            }
            otherAgreements {
              specialFloorTreatment
              continuosDescaling
              repairmentInfo
              gardenCare
              landlordAccess
              watering
              wasteInformation
              buildingInspection
              contractFurniture
              firstMoveIn
              wallWorks {
                roomNotes
              }
              cleaning {
                specialDirt
                bathDescaling
                jointing
                showerCabin
                showerSealing
                siliconeJoint
                floorCleaning
                balconyCleaning
              }
            }
            keys {
              received
              notes
              images {
                id
                storageUrl
                type
                description
              }
            }
            deposit {
              received
              amount
              paymentMethod
              notes
            }
            emails
            documents {
              id
              storageUrl
              type
              description
              title
              documentFrom
              state
            }
            agent {
              id
              email
              isRemax
              themeConfig
              firstName
              lastName
              picture
              officeName
              officeAddress
              legalEntity
              phone
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId,
        hopId: hopId,
      },
    }).pipe(
      first(),
      map((result: any) => ({
        ...result.data.handoverProtocol,
        state: result.state || 'pending',
        lastVisited: new Date(result.data.handoverProtocol.lastVisited),
      })),
    );
  }

  createHandoverProtocolDocument$(
    objectId: string,
    hopId: string,
    agentCompany: AgentCompany
  ): Observable<string> {
    return this._apollo.query({
      query: gql`
        query CreateHandoverProtocolDocument(
          $objectId: String!
          $handoverProtocolId: String!
          $agentCompany: AgentCompany!
        ) {
          handoverProtocolDocument(
            objectId: $objectId
            handoverProtocolId: $handoverProtocolId
            agentCompany: $agentCompany
          ) {
            url
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId,
        handoverProtocolId: hopId,
        agentCompany: agentCompany,
      },
    }).pipe(
      first(),
      map((result: any) => result.data.handoverProtocolDocument.url),
    );
  }

  compressFiles$(objectId: string, filePaths: string[], filenames: string[]): Observable<string> {
    return this._apollo.query({
      query: gql`
        query CompressFiles(
          $objectId: String!
          $filePaths: [String!]!
          $filenames: [String!]!
        ) {
          compressFiles(
            objectId: $objectId
            filePaths: $filePaths
            filenames: $filenames
          )
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId,
        filePaths: filePaths,
        filenames: filenames,
      },
    }).pipe(
      first(),
      map((result: any) => result.data.compressFiles),
    );
  }

  loadHandoverProtocols$(objectId: string): Observable<HandoverProtocol[]> {
    return this._apollo.query({
      query: gql`
        query GetHandoverProtocols($objectId: String!) {
          handoverProtocolsByObject(objectId: $objectId) {
            id
            objectId
            state
            lastVisited
            transfereeType
            transfereesData {
              id
              title
              firstname
              lastname
            }
            businessTransfereeData {
              id
              companyNumber
              companyRegister
              customers {
                id
                title
                firstname
                lastname
                contact {
                  email
                }
              }
            }
            transferorType
            transferorsData {
              id
              title
              firstname
              lastname
            }
            businessTransferorData {
              id
              companyNumber
              companyRegister
              customers {
                id
                title
                firstname
                lastname
                contact {
                  email
                }
              }
            }
            object {
              objectStatus
              address {
                country
                city
                zipCode
                streetName
                buildingNumber
                doorNumber
                stairway
              }
            }
            signatureData {
              signedDocumentUrl
            }
            creator {
              isCreatedByMe
              name
              email
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId,
      },
    }).pipe(first(), map((result: any) => result.data.handoverProtocolsByObject));
  }

  loadHandoverProtocolsByCustomer$(customerId: string): Observable<HandoverProtocol[]> {
    return this._apollo.query({
      query: gql`
        query GetHandoverProtocolsByCustomer($customerId: String!) {
          handoverProtocolsByCustomer(customerId: $customerId) {
            id
            objectId
            state
            lastVisited
            transfereeType
            transfereesData {
              id
              title
              firstname
              lastname
            }
            businessTransfereeData {
              id
              companyNumber
              companyRegister
              customers {
                id
                title
                firstname
                lastname
                contact {
                  email
                }
              }
            }
            transferorType
            transferorsData {
              id
              title
              firstname
              lastname
            }
            businessTransferorData {
              id
              companyNumber
              companyRegister
              customers {
                id
                title
                firstname
                lastname
                contact {
                  email
                }
              }
            }
            object {
              objectStatus
              address {
                country
                city
                zipCode
                streetName
                buildingNumber
                doorNumber
                stairway
              }
            }
            signatureData {
              signedDocumentUrl
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        customerId,
      },
    }).pipe(first(), map((result: any) => result.data.handoverProtocolsByCustomer));
  }

  getSignedHOPsAmount$(objectId: string): Observable<{ amount: number }> {
    return this._apollo.query({
      query: gql`
        query GetSignedHOPsAmount($objectId: String!) {
          signedHOPsAmount(objectId: $objectId) {
            amount
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: { objectId },
    }).pipe(first(), map((result: any) => result.data.signedHOPsAmount));
  }

  getSignedHOPsDocsUrl$(objectId: string): Observable<{ storageUrl: string }> {
    return this._apollo.query({
      query: gql`
        query GetSignedHOPsDocsUrl($objectId: String!) {
          signedHOPsDocsUrl(objectId: $objectId) {
            storageUrl
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: { objectId },
    }).pipe(
      first(),
      map((result: any) => result.data.signedHOPsDocsUrl),
      catchError((e) => {
        this._snackbarService.showSnackbar(
          'Download fehlgeschlagen',
          'mat-warn',
          true
        )
        return of({ storageUrl: '' });
      })
    );
  }

  signAndCompleteHandoverProtocol$(
    objectId: string,
    handoverProtocolId: string,
    transferorsSignatureData: SigneeSignatureResult[] | null,
    transfereesSignatureData: SigneeSignatureResult[] | null,
    agentCompany: AgentCompany
  ): Observable<SignedAndCompletedHandoverProtocol> {
    return this._apollo.mutate({
      mutation: gql`
        mutation SignAndCompleteHandoverProtocol(
          $signatureInput: HandoverProtocolSignatureInput!
          $agentCompany: AgentCompany!
        ) {
          signAndCompleteHandoverProtocol(
            signatureInput: $signatureInput
            agentCompany: $agentCompany
          ) {
            id
            state
            lastVisited
            objectId
            transferorType
            transferors
            businessTransferor
            transfereeType
            transferees
            businessTransferee
            signatureData {
              signedDocumentUrl
            }
          }
        }
      `,
      variables: {
        signatureInput: {
          objectId,
          handoverProtocolId,
          transferorsSignatureData: transferorsSignatureData ?
            transferorsSignatureData.map((s) => mapSignatureDataForGraphQL(s)) : null,
          transfereesSignatureData: transfereesSignatureData ?
            transfereesSignatureData.map((s) => mapSignatureDataForGraphQL(s)) : null,
        },
        agentCompany,
      },
    })
      .pipe(
        map((result: any) => {
          const untypedHandoverProtocol = result.data.signAndCompleteHandoverProtocol;

          const handoverProtocol = untypedHandoverProtocol as SignedAndCompletedHandoverProtocol;
          handoverProtocol.lastVisited = new Date(handoverProtocol.lastVisited);
          handoverProtocol.transferors = untypedHandoverProtocol.transferors ?? [];
          handoverProtocol.transferees = untypedHandoverProtocol.transferees ?? [];

          return handoverProtocol;
        })
      );
  }

  sendHandoverProtocolEmail$(
    objectId: string,
    handoverProtocolId: string,
    emailData: any,
  ): Observable<boolean> {
    return this._apollo.query({
      query: gql`
        query TriggerSendHandoverProtocolEmailQuery(
          $objectId: String!
          $handoverProtocolId: String!
          $emailData: EmailData!
        ) {
          handoverProtocolSendEmail(
            objectId: $objectId
            handoverProtocolId: $handoverProtocolId
            emailData: $emailData
          )
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        objectId,
        handoverProtocolId,
        emailData,
      },
    })
      .pipe(
        first(),
        map((result: ApolloQueryResult<any>) => {
          const success = result.data?.handoverProtocolSendEmail;

          if (!success) {
            throw new Error();
          }

          return true;
        }),
      );
  }

  getHandoverProtocolEmailVariables$(
    objectId: string,
    handoverProtocolId: string,
    agentCompany: AgentCompany,
  ): Observable<boolean> {
    return this._apollo.query({
      query: gql`
        query GetHandoverProtocolEmailVariables(
          $objectId: String!
          $handoverProtocolId: String!
          $agentCompany: AgentCompany!
        ) {
          handoverProtocolEmailVariables(
            objectId: $objectId
            handoverProtocolId: $handoverProtocolId
            agentCompany: $agentCompany
          ) {
            customerPortalLink
            propertyAddress
            documentUrl
            signature
          }
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        objectId,
        handoverProtocolId,
        agentCompany,
      },
    }).pipe(
      first(),
      map((result: ApolloQueryResult<any>) => result.data?.handoverProtocolEmailVariables),
    );
  }
}
