import {Injectable, OnDestroy, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Object } from '@domains/object';
import { UsageBoundCostsForm } from '../object/object-form.service';
import {
  DEPOSITMETHOD,
  PROPERTYTYPE,
  TENANCYLAWAPPLICATION,
} from '@domains/rental-offer';
import { BusinessCustomerFormService } from '@domains/business-customer';
import { CustomerType } from 'src/app/common-components/customer-type-selector/model';
import { CustomerFormService } from '@domains/customer';
import {PlainAgreement} from "./plain-agreement.model";
import {CONTRACT, DOCSTATUS, ENERGYPASSAVAILABLE, ENERGYPASSRESPONSIBLE, OWNERSHIP} from "../exclusive-agreement";
import {SettingsService} from "services/settings.service";

@Injectable({ providedIn: 'root' })
export class PlainAgreementFormService implements OnInit, OnDestroy {
  phoneValidator = Validators.pattern('[- +()0-9]+');
  taxAmount: number = 0;

  private _subscription = new Subscription();

  ngOnInit() {
    this._settings.getDocumentsSettings().subscribe((settings) => {
      this.taxAmount = settings.taxBase;
    });
  }

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _customerFormService: CustomerFormService,
    private readonly _businessCustomerFormService: BusinessCustomerFormService,
    private readonly _settings: SettingsService,
  ) { }

  private createRoomsAndAreaFormGroup(): FormGroup {
    return this._fb.group({
      livingArea: this.createRoomsAndAreaItemFormGroup(),
      baseArea: this.createRoomsAndAreaItemFormGroup(),
      usableArea: this.createRoomsAndAreaItemFormGroup(),
    });
  }

  private createRoomsAndAreaItemFormGroup(): FormGroup {
    return this._fb.group({
      area: [null],
      areaCa: true,
    });
  }

  createPropertyDetailsForm(object?: Object): FormGroup {
    return this._fb.group({
      property: this._fb.group({
        use: '', // new
        constructionYear: [
          object?.additionalInformation?.constructionYear ?? 0,
          [Validators.max(new Date().getFullYear())],
        ],
        constructionYearCa: object?.additionalInformation?.constructionYearCa ?? false,
        cadastralCommunity: object?.additionalInformation?.cadastralCommunity ?? '',
        entryNumber: object?.additionalInformation?.entryNumber ?? '',
        parcelNumber: object?.additionalInformation?.parcelNumber ?? '',
        parcelNumbers: this._fb.array((object?.additionalInformation?.parcelNumbers ?? [])),
        movingInDate: object?.propertyDetails?.movingInDate ?? new Date().toISOString().substring(0, 10),
        propertyType: PROPERTYTYPE.ALLEINEIGENTUM,
        propertyTypeNotes: '',
        shareInOwnership: '',
      }),
      roomsAndArea: this.createRoomsAndAreaFormGroup(),
      rulesOfUse: false,
      usageBoundCosts: this._fb.group({
        givenLater: false,
        costs: 0,
        operatingCostsTax: false,
        electricity: false,
        heating: false,
        telecommunication: false,
        water: false,
        other: false,
        otherDetails: '',
      }) as FormGroup<UsageBoundCostsForm>,
    });
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public createGeneralDataForm(): FormGroup {
    return this._fb.group({
      contract: CONTRACT.BUYINGCONTRACT,
    });
  }

  public createGeneralConditionsForm(): FormGroup {
    return this._fb.group({
      firstPrincipal: false,
      doubleAgent: false,
      jointBusiness: false,
      contractNotes: '',
    });
  }

  public createEnergyPassForm(): FormGroup {
    return this._fb.group({
      energyPassAvailable: ENERGYPASSAVAILABLE.ISAVAILABLE,
      energyPassResponsible: ENERGYPASSRESPONSIBLE.AGENT,
      energyPassDelivery: '',
    });
  }

  public createFurtherConditionsForm(): FormGroup {
    return this._fb.group({
      contractInOffice: false,
      faggExplained: true,
      faggAndCancelation: true,
      startBeforeCancelation: true,
    });
  }

  public createDataProcessingForm(): FormGroup {
    return this._fb.group({
      generalAgreement: true,
      newsletterAgreed: true,
      questionaireAgreed: true,
      propupAgreed: [true, Validators.requiredTrue],
      headOrganisationAgreed: [true, Validators.requiredTrue],
    });
  }

  public createContractEntitiesArrayForm(): FormGroup {
    return this._fb.group({
      customer: this._customerFormService.createCustomerFormGroup(true),
      contractEntity: this.createContractEntityForm(),
    });
  }

  public createContractEntityForm(id?: string): FormGroup {
    return this._fb.group({
      customerId: id ?? '',
      ownership: OWNERSHIP.SELFOWNERSHIP,
      ownershipProof: '',
    });
  }

  public createContractEntityTrusteeshipForm(): FormGroup {
    return this._fb.group({
      trusteeship: true,
      trusteeshipOwner: '',
    });
  }

  public createCommissionBuyForm(object?: Object): FormGroup {
    return this._fb.group({
      commissionCalculationType: object?.commissionBuy?.commissionCalculationType ?? 'percentage',
      commissionPercentage: object?.commissionBuy?.commissionPercentage ?? 3,
      commissionTransfer: false,
      commission: null,
      vat: object?.commissionBuy?.vat ?? this.taxAmount,
      additionalNotes: '',
    })
  }

  public createCommissionRentForm(object?: Object): FormGroup {
    return this._fb.group({
      commissionCalculationType: object?.commissionRent?.commissionCalculationType ?? 'rentBased',
      commissionTotal: null,
      commissionMonths: object?.commissionRent?.commissionMonths ?? 2,
      commissionPercentageSpecial: null,
      commission: null,
      vat: object?.commissionRent?.vat ?? this.taxAmount,
      additionalNotes: '',
    })
  }

  public createDocumentsArrayItemForm(): FormGroup {
    return this._fb.group({
      id: '',
      description: '',
      type: '',
      storageUrl: '',
      title: '',
      documentFrom: new Date().toISOString().substring(0, 10),
      state: ''
    });
  }

  public createNeededDocumentsForm(): FormGroup {
    return this._fb.group({
      landRegister: DOCSTATUS.INACCURATE,
      landZoning: DOCSTATUS.INACCURATE,
      developmentPlan: DOCSTATUS.INACCURATE,
      contaminatedSitesPlan: DOCSTATUS.INACCURATE,
      cadastralMap: DOCSTATUS.INACCURATE,
      buildingPermit: DOCSTATUS.INACCURATE,
      floorPlan: DOCSTATUS.INACCURATE,
      permitOfUsePlan: DOCSTATUS.INACCURATE,
      energyPass: DOCSTATUS.INACCURATE,
      condominiumContract: DOCSTATUS.INACCURATE,
      usefulValueAppraisal: DOCSTATUS.INACCURATE,
      advancePayment: DOCSTATUS.INACCURATE,
      annualStatement: DOCSTATUS.INACCURATE,
      operatingCosts: DOCSTATUS.INACCURATE,
      ownersMeetingProtocol: DOCSTATUS.INACCURATE,
      supplyContracts: DOCSTATUS.INACCURATE,
      disposalContracts: DOCSTATUS.INACCURATE,
      insuranceContracts: DOCSTATUS.INACCURATE,
      inventoryList: DOCSTATUS.INACCURATE,
      rentalContract: DOCSTATUS.INACCURATE,
      landRegisterCharges: DOCSTATUS.INACCURATE,
      pictures: DOCSTATUS.INACCURATE,
      otherDocs: this._fb.array([]),
    })
  }

  public createOtherDocsArrayItemForm(): FormGroup {
    return this._fb.group({
      description: '',
      docStatus: DOCSTATUS.HANDEDOVER,
    })
  }

  public createAdditionalEAServiceForm(): FormGroup {
    return this._fb.group({
      rentControlPackage: false,
      fairRentService: this._fb.group({
        isSelected: false,
        level: null,
      })
    })
  }

  public createPlainAgreementForm(object?: Object): FormGroup {
    const formGroup = this._fb.group({
      generalData: this.createGeneralDataForm(),
      generalConditions: this.createGeneralConditionsForm(),
      energyPass: this.createEnergyPassForm(),
      furtherConditions: this.createFurtherConditionsForm(),
      dataProcessing: this.createDataProcessingForm(),
      additionalEAServices: this.createAdditionalEAServiceForm(),
      contractEntityType: [CustomerType.Private],
      contractEntities: this._fb.array([]),
      businessContractEntity: this._fb.group({
        data: this._businessCustomerFormService.createBusinessCustomerFormGroup(),
        customers: this._businessCustomerFormService.createPrivateCustomers(),
        ownership: OWNERSHIP.SELFOWNERSHIP,
        ownershipProof: '',
      }),
      contractEntityTrusteeship: this.createContractEntityTrusteeshipForm(),
      commissionBuy: this.createCommissionBuyForm(object),
      commissionRent: this.createCommissionRentForm(object),
      documents: this._fb.array([]),
      neededDocuments: this.createNeededDocumentsForm(),
      agent: [{}],
    }) as FormGroup;

    formGroup.get('contractEntityType')?.valueChanges.subscribe((value) => {
      if (value === CustomerType.Private) {
        formGroup.get('businessContractEntity')?.disable();
        formGroup.get('contractEntities')?.enable();
      } else if (value === CustomerType.Business) {
        formGroup.get('businessContractEntity')?.enable();
        formGroup.get('contractEntities')?.disable();
      }
    });

    formGroup.addControl('propertyDetails', this.createPropertyDetailsForm());
    formGroup.addControl('salesSpecifications', this.createSalesSpecificationsForm());
    formGroup.addControl('rentalSpecifications', this.createRentalSpecifications());

    return formGroup;
  }

  public createInitialContractEntitiesForm(object?: Object){
    const result = [];
    const customerIds =object?.owners?.map(owner => owner.customerId) ?? [];

    for(const id of customerIds) {
      result.push(this.createContractEntityForm(id).value);
    }
    if (result.length === 0) {
      result.push(this.createContractEntityForm().value);
    }
    return result;
  }

  public createInitialContractEntityType(object?: Object){
    if (object?.ownerType === 'business') {
      return CustomerType.Business;
    } else return CustomerType.Private;
  }

  public createInitialBusinessContractEntityForm(object?: Object){
    const businessCustomerId = object?.businessOwner?.customerId;
    if (!businessCustomerId) {
      return undefined;
    } else return businessCustomerId
  }

  public createPlainAgreementInitialValue(
    object?: Object,
  ): PlainAgreement {
    const plainAgreementForm = this.createPlainAgreementForm(object);
    const initialContractEntitiesForm = this.createInitialContractEntitiesForm(object);
    const initialContractEntityType = this.createInitialContractEntityType(object);
    const initialBusinessContractEntity = this.createInitialBusinessContractEntityForm(object);

    return {
      ...plainAgreementForm.value,
      generalData: {
        contract:
          object?.orderType === 'buy'
            ? CONTRACT.BUYINGCONTRACT
            : CONTRACT.RENTALCONTRACT,
      },
      objectId: object?.id,
      state: 'pending',
      lastVisited: '',
      contractEntityType: initialContractEntityType,
      contractEntities: initialContractEntitiesForm,
      businessContractEntity: {
        customerId: initialBusinessContractEntity,
      },
    };
  }

  public addCustomerValidators(customerGroup: FormGroup): void {
    const firstnameControl = customerGroup.get('firstname');
    const lastnameControl = customerGroup.get('lastname');
    const emailControl = customerGroup.get('contact.email');

    if (firstnameControl) {
      firstnameControl.setValidators([Validators.required]);
      firstnameControl.updateValueAndValidity();
    }
    if (lastnameControl) {
      lastnameControl.setValidators([Validators.required]);
      lastnameControl.updateValueAndValidity();
    }
    if (emailControl) {
      emailControl.setValidators([Validators.email, Validators.required]);
      emailControl.updateValueAndValidity();
    }

    customerGroup.markAllAsTouched();
    customerGroup.markAsDirty();
  }

  private createSalesSpecificationsForm(): FormGroup {
    return this._fb.group({
      sellingPrice: null,
      hasVATLiabilityOption: false,
      applicationPrice: null,
      hasPriceOnRequest: false,
      hasPriceToBeDiscussed: false,
      includedInThePurchasePrice: null,
      misc: null,
      hasKnownHiddenDefects: false,
      hiddenDefects: null,
      hasEntriesInLandRegister: false,
      entriesInLandRegister: null,
    });
  }

  private createRentalSpecifications(): FormGroup {
    return this._fb.group({
      tenancyLaw: false,
      tenancyLawApplication: TENANCYLAWAPPLICATION.FULL,
      hasCommercialUseAllowance: false,
      hasSubLeasingAllowance: false,
      hasLeasingTerm: false,
      contractValidUntil: null,
      price: this._fb.group({
        rent: null,
        vat: null,
      }),
      valorization: this._fb.group({
        hasValorization: true,
        vpi: null,
      }),
      rentalDeposit: this._fb.group({
        depositMethod: DEPOSITMETHOD.NOTDEFINED,
        depositAmount: null,
      }),
      singlePayment: this._fb.group({
        singlePayment: false,
        singlePaymentInfo: null,
        singlePaymentAmount: null,
      }),
      misc: null,
    });
  }
}
