import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';
import { SigneeSignatureResult } from '@domains/handover-protocol';
import { Apollo, gql } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { SnackbarService } from 'services/snackbar.service';
import { AgentCompany } from 'src/app/common-models/agent-company';
import {
  ViewingPass,
  ViewingPassCreation,
  SignedAndCompletedViewingPass,
} from './viewing-pass.model';
import { mapSignatureDataForGraphQL } from 'src/app/helper/mapSignatureDataForGraphQL';
import { EmailPreviewBodyDto } from '@types';

@Injectable({ providedIn: 'root' })
export class ViewingPassApiFacade {
  constructor(
    private readonly apollo: Apollo,
    private readonly _snackbarService: SnackbarService,
  ) { }

  saveViewingPass$(data: ViewingPassCreation): Observable<ViewingPass> {
    return this.apollo.mutate({
      mutation: gql`
        mutation SaveViewingPass($pass: ViewingPassCreation!) {
          viewingPass(pass: $pass) {
            id
            state
            vp_type
            objectId
            creationDate
            lastVisited
            customerType
            customers
            businessCustomer
            legalInformation {
              dataProcessing {
                commissionedAgent
                agreedToNewsletter
                agreedToSurvey
                agreedToDataTransmissionToFranchise
              }
              detailInformation {
                agentRepresentsBothParties
                agentHasSellerRelationship
                instructedCancellationConditions
                cancellationNotification
                signedInAgentOffice
                agentCanTakeActionBeforeCancellationPeriod
              }
              cancellationPeriod {
                approvedAgentCanTakeActionBeforeCancellationPeriod
              }
              feeCalculation {
                objectPrice
                feeCalculationType
                feeAtFailedIntermediation
                amount

                # Only Buy
                percentage

                # Only Rent
                feeBasisPerMonth
                monthsToPayFeeBasis
              }
              feeAtFailedIntermediation {
                approvedFeeAtFailedIntermediation
              }
              emails
            }
            documentNotes {
              sellingLead
              financingStatus
              financingOffer {
                agentOffer
                fimobilia
                realfinanz
                novius
                financingContractPartnerId
              }
              notes
              viewingDate
              dave
              creditCheck
              sendCustomerPortalLink
            }
            documents {
              id
              storageUrl
              type
              description
              title
              documentFrom
              state
            }
            agent {
              id
              email
              isRemax
              themeConfig
              firstName
              lastName
              picture
              officeName
              officeAddress
              legalEntity
              phone
            }
          }
        }
      `,
      variables: {
        pass: data,
      },
    }).pipe(
      first(),
      map((result: any) => ({
        ...result.data.viewingPass,
        lastVisited: new Date(result.data.viewingPass.lastVisited),
        creationDate: new Date(result.data.viewingPass.creationDate),
      })),
    );
  }

  loadViewingPassDetails$(objectId: string, viewingPassId: string): Observable<ViewingPass> {
    return this.apollo.query({
      query: gql`
        query getViewingPassDetails($objectId: String!, $viewingPassId: String!) {
          viewingPass(objectId: $objectId, viewingPassId: $viewingPassId) {
            id
            state
            vp_type
            objectId
            customerType
            customers
            customersData {
              id
              birthdate
              isConsumer
              title
              firstname
              lastname
              nationality
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                  longitude
                  latitude
                }
                phone
                email
              }
              pep {
                isPep
                wasPepLastYear
                isFamilyMemberPep
                isKnownToBeCloseToPep
                firstName
                lastName
              }
              IDImages {
                id
                storageUrl
                type
                description
              }
            }
            businessCustomer
            businessCustomerData {
              id
              companyNumber
              companyRegister
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                }
                phone
                email
              }
              customers {
                id
                birthdate
                title
                firstname
                lastname
                nationality
                contact {
                  address {
                    country
                    city
                    zipCode
                    streetName
                    buildingNumber
                    doorNumber
                    stairway
                    longitude
                    latitude
                  }
                  phone
                  email
                }
                pep {
                  isPep
                  wasPepLastYear
                  isFamilyMemberPep
                  isKnownToBeCloseToPep
                  firstName
                  lastName
                }
                IDImages {
                  id
                  storageUrl
                  type
                  description
                }
              }
            }
            lastVisited
            creationDate
            signatureData {
              signedDocumentUrl
            }
            legalInformation {
              dataProcessing {
                commissionedAgent
                agreedToNewsletter
                agreedToSurvey
                agreedToDataTransmissionToFranchise
              }
              detailInformation {
                agentRepresentsBothParties
                agentHasSellerRelationship
                instructedCancellationConditions
                cancellationNotification
                signedInAgentOffice
                agentCanTakeActionBeforeCancellationPeriod
              }
              cancellationPeriod {
                approvedAgentCanTakeActionBeforeCancellationPeriod
              }
              feeCalculation {
                objectPrice
                feeCalculationType
                feeAtFailedIntermediation
                amount

                # Only Buy
                percentage

                # Only Rent
                feeBasisPerMonth
                monthsToPayFeeBasis
              }
              feeAtFailedIntermediation {
                approvedFeeAtFailedIntermediation
              }
              emails
            }
            documentNotes {
              sellingLead
              financingStatus
              financingOffer {
                agentOffer
                fimobilia
                realfinanz
                novius
                financingContractPartnerId
              }
              notes
              viewingDate
              dave
              creditCheck
              sendCustomerPortalLink
            }
            documents {
              id
              storageUrl
              type
              description
              title
              documentFrom
              state
            }
            isFromCustomerPortal
            agent {
              id
              email
              isRemax
              themeConfig
              firstName
              lastName
              picture
              officeName
              officeAddress
              legalEntity
              phone
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId,
        viewingPassId: viewingPassId,
      },
    }).pipe(
      first(),
      map((result: any) => ({
        ...result.data.viewingPass,
        state: result.state || 'pending',
        lastVisited: new Date(result.data.viewingPass.lastVisited),
        creationDate: new Date(result.data.viewingPass.creationDate),
      })),
    );
  }

  createViewingPassDocument$(objectId: string, viewingPassId: string, agentCompany: AgentCompany): Observable<string> {
    return this.apollo.query({
      query: gql`
        query GetViewingPassDocument(
          $objectId: String!
          $passId: String!
          $agentCompany: AgentCompany!
        ) {
          viewingPassDocument(
            objectId: $objectId
            passId: $passId
            agentCompany: $agentCompany
          ) {
            url
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId,
        passId: viewingPassId,
        agentCompany: agentCompany,
      },
    }).pipe(
      first(),
      map((result: any) => result.data.viewingPassDocument.url),
    );
  }

  loadViewingPasses$(objectId: string): Observable<ViewingPass[]> {
    return this.apollo.query({
      query: gql`
        query GetViewingPasses($objectId: String!) {
          viewingPasses(objectId: $objectId) {
            id
            objectId
            lastVisited
            creationDate
            state
            vp_type
            customerType
            customersData {
              id
              title
              firstname
              lastname
              contact {
                email
              }
            }
            businessCustomerData {
              id
              companyNumber
              companyRegister
              customers {
                id
                title
                firstname
                lastname
                contact {
                  email
                }
              }
            }
            signatureData {
              signedDocumentUrl
            }
            object {
              objectStatus
              address {
                country
                city
                zipCode
                streetName
                buildingNumber
                doorNumber
                stairway
              }
            }
            documentNotes {
              sellingLead
              financingStatus
            }
            creator {
              isCreatedByMe
              name
              email
            }
            isFromCustomerPortal
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId,
      },
    }).pipe(first(), map((result: any) => result.data.viewingPasses));
  }

  loadViewingPassesByCustomer$(customerId: string): Observable<ViewingPass[]> {
    return this.apollo.query({
      query: gql`
        query GetViewingPasses($customerId: String!) {
          viewingPassesByCustomer(customerId: $customerId) {
            id
            objectId
            lastVisited
            creationDate
            state
            vp_type
            customerType
            customersData {
              id
              title
              firstname
              lastname
              contact {
                email
              }
            }
            documentNotes {
              sellingLead
              financingStatus
            }
            businessCustomerData {
              id
              companyNumber
              companyRegister
              customers {
                id
                title
                firstname
                lastname
                contact {
                  email
                }
              }
            }
            signatureData {
              signedDocumentUrl
            }
            object {
              objectStatus
              address {
                country
                city
                zipCode
                streetName
                buildingNumber
                doorNumber
                stairway
              }
            }
            isFromCustomerPortal
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        customerId,
      },
    }).pipe(first(), map((result: any) => result.data.viewingPassesByCustomer));
  }

  getLastViewingPassDetails$(objectId: string): Observable<ViewingPass> {
    return this.apollo.query({
      query: gql`
        query GetLastViewingPass($objectId: String!) {
          lastViewingPass(objectId: $objectId) {
            id
            state
            vp_type
            objectId
            lastVisited
            customerType
            legalInformation {
              dataProcessing {
                commissionedAgent
                agreedToNewsletter
                agreedToSurvey
                agreedToDataTransmissionToFranchise
              }
              detailInformation {
                agentRepresentsBothParties
                agentHasSellerRelationship
                instructedCancellationConditions
                cancellationNotification
                signedInAgentOffice
                agentCanTakeActionBeforeCancellationPeriod
              }
              cancellationPeriod {
                approvedAgentCanTakeActionBeforeCancellationPeriod
              }
              feeCalculation {
                objectPrice
                feeCalculationType
                feeAtFailedIntermediation
                amount

                # Only Buy
                percentage

                # Only Rent
                feeBasisPerMonth
                monthsToPayFeeBasis
              }
              feeAtFailedIntermediation {
                approvedFeeAtFailedIntermediation
              }
              emails
            }
            documents {
              id
              storageUrl
              type
              description
              title
              documentFrom
              state
            }
            agent {
              id
              email
              isRemax
              themeConfig
              firstName
              lastName
              picture
              officeName
              officeAddress
              legalEntity
              phone
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: { objectId },
    }).pipe(first(), map((result: any) => result.data.lastViewingPass));
  }

  getSignedViewingPassesAmount$(objectId: string): Observable<{ amount: number }> {
    return this.apollo.query({
      query: gql`
        query GetSignedViewingPassesAmount($objectId: String!) {
          signedViewingPassesAmount(objectId: $objectId) {
            amount
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: { objectId },
    }).pipe(first(), map((result: any) => result.data.signedViewingPassesAmount));
  }

  getSignedViewingPassesDocsUrl$(objectId: string): Observable<{ storageUrl: string }> {
    return this.apollo.query({
      query: gql`
        query GetSignedViewingPassesDocsUrl($objectId: String!) {
          signedViewingPassesDocsUrl(objectId: $objectId) {
            storageUrl
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: { objectId },
    }).pipe(
      first(),
      map((result: any) => result.data.signedViewingPassesDocsUrl),
      catchError((e) => {
        this._snackbarService.showSnackbar(
          'Download fehlgeschlagen',
          'mat-warn',
          true
        )
        return of({ storageUrl: '' });
      })
    );
  }

  signAndCompleteViewingPass$(
    objectId: string,
    viewingPassId: string,
    customersSignatureData: SigneeSignatureResult[],
    agentCompany: AgentCompany
  ): Observable<SignedAndCompletedViewingPass> {
    return this.apollo.mutate({
      mutation: gql`
        mutation SignAndCompleteViewingPass(
          $signatureInput: ViewingPassSignatureInput!
          $agentCompany: AgentCompany!
        ) {
          signAndCompleteViewingPass(
            signatureInput: $signatureInput
            agentCompany: $agentCompany
          ) {
            id
            state
            vp_type
            lastVisited
            creationDate
            customerType
            customers
            businessCustomer
            signatureData {
              signedDocumentUrl
            }
          }
        }
      `,
      variables: {
        signatureInput: {
          objectId,
          viewingPassId,
          customersSignatureData: customersSignatureData.map((s) => mapSignatureDataForGraphQL(s)),
        },
        agentCompany,
      },
    })
      .pipe(
        map((result: any) => {
          const typedResult = result.data.signAndCompleteViewingPass as SignedAndCompletedViewingPass;
          typedResult.lastVisited = new Date(result.data.signAndCompleteViewingPass.lastVisited);
          typedResult.creationDate = new Date(result.data.signAndCompleteViewingPass.creationDate);

          return typedResult;
        })
      );
  }

  sendViewingPassEmail$(
    viewingPassId: string,
    emailData: any,
    agentCompany: AgentCompany,
  ): Observable<boolean> {
    return this.apollo.query({
      query: gql`
        query TriggerSendViewingPassEmailQuery(
          $viewingPassId: String!
          $emailData: EmailData!
          $agentCompany: AgentCompany!
        ) {
          viewingPassSendMail(
            viewingPassId: $viewingPassId
            emailData: $emailData
            agentCompany: $agentCompany
          )
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        viewingPassId,
        emailData,
        agentCompany,
      },
    }).pipe(
      first(),
      map((result: ApolloQueryResult<any>) => {
        const success = result.data.viewingPassSendMail;

        if (!success) {
          throw new Error();
        }

        return true;
      }),
    );
  }

  sendCustomerPortalLinkEmail$(
    viewingPassId: string,
    emailData: any,
  ): Observable<any> {
    return this.apollo.query({
      query: gql`
        query ViewingPassCustomerPortalLinkSendEmail(
          $viewingPassId: String!
          $emailData: EmailData!
        ) {
          viewingPassCustomerPortalLinkSendEmail(
            viewingPassId: $viewingPassId
            emailData: $emailData
          )
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        viewingPassId,
        emailData,
      },
    }).pipe(
      first(),
      map((result: ApolloQueryResult<any>) => {
        const success = result.data?.viewingPassCustomerPortalLinkSendEmail;

        if (!success) {
          throw new Error();
        }

        return true;
      }),
    );
  }

  getViewingPassCustomerPortalLinkEmailVariables$(
    viewingPassId: string,
    handInPass: boolean,
  ): Observable<any> {
    return this.apollo.query({
      query: gql`
        query ViewingPassCustomerPortalLinkEmailVariables(
          $viewingPassId: String!
          $handInPass: Boolean
        ) {
          viewingPassCustomerPortalLinkEmailVariables(
            viewingPassId: $viewingPassId
            handInPass: $handInPass
          ) {
            propertyAddress
            signature
            customerPortalLink
          }
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        viewingPassId,
        handInPass,
      },
    }).pipe(
      first(),
      map((result: any) => result.data.viewingPassCustomerPortalLinkEmailVariables)
    );
  }

  getViewingPassEmailVariables$(
    viewingPassId: string,
    agentCompany: AgentCompany,
  ): Observable<any> {
    return this.apollo.query({
      query: gql`
        query GetViewingPassEmailVariables(
          $viewingPassId: String!
          $agentCompany: AgentCompany!
        ) {
          viewingPassEmailVariables(
            viewingPassId: $viewingPassId
            agentCompany: $agentCompany
          ) {
            propertyAddress
            documentUrl
            signature
            customerPortalText
            fimobiliaFinancialOfferText
          }
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        viewingPassId,
        agentCompany,
      },
    }).pipe(
      first(),
      map((result: any) => result.data.viewingPassEmailVariables),
    );
  }

  deleteViewingPass$(viewingPassId: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: gql`
        mutation DeleteViewingPass($viewingPassId: String!) {
          deleteViewingPass(viewingPassId: $viewingPassId)
        }
      `,
      variables: {
        viewingPassId,
      },
    }).pipe(
      first(),
      map((result: any) => result.data.deleteViewingPass),
    );
  }

  compressFiles$(objectId: string, viewingPassId: string, filePaths: string[], filenames: string[]): Observable<string> {
    return this.apollo.query({
      query: gql`
        query CompressViewingPassFiles(
          $objectId: String!
          $viewingPassId: String!
          $filePaths: [String!]!
          $filenames: [String!]!
        ) {
          compressViewingPassFiles(
            objectId: $objectId
            viewingPassId: $viewingPassId
            filePaths: $filePaths
            filenames: $filenames
          )
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId,
        viewingPassId,
        filePaths,
        filenames,
      },
    }).pipe(
      first(),
      map((result: any) => result.data.compressViewingPassFiles),
    );
  }
}
