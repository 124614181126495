import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AgentCompany } from 'src/app/common-models/agent-company';
import { PlainAgreement, SignedAndCompletedPlainAgreement } from './plain-agreement.model';
import { ApolloQueryResult } from "@apollo/client";
import { SigneeSignatureResult } from "@domains/handover-protocol";
import { mapSignatureDataForGraphQL } from 'src/app/helper/mapSignatureDataForGraphQL';
import { EmailPreviewBodyDto } from '@types';

@Injectable({ providedIn: 'root' })
export class PlainAgreementApiFacade {
  constructor(private readonly apollo: Apollo) { }

  savePlainAgreement$(data: any): Observable<PlainAgreement> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation SavePlainAgreement($plainAgreement: PlainAgreementInput) {
            plainAgreement(plainAgreement: $plainAgreement) {
              id
              objectId
              state
              lastVisited
              generalData {
                contract
              }
              generalConditions {
                firstPrincipal
                doubleAgent
                jointBusiness
                contractNotes
              }
              energyPass {
                energyPassAvailable
                energyPassResponsible
                energyPassDelivery
              }
              propertyDetails {
                property {
                  use
                  constructionYear
                  constructionYearCa
                  cadastralCommunity
                  entryNumber
                  parcelNumber
                  parcelNumbers
                  movingInDate
                  propertyType
                  propertyTypeNotes
                  shareInOwnership
                }
                rulesOfUse
                roomsAndArea {
                  livingArea {
                    area
                    areaCa
                  }
                  baseArea {
                    area
                    areaCa
                  }
                  usableArea {
                    area
                    areaCa
                  }
                }
                usageBoundCosts {
                  givenLater
                  costs
                  operatingCostsTax
                  electricity
                  gas
                  heating
                  telecommunication
                  water
                  other
                  otherDetails
                }
              }
              salesSpecifications {
                sellingPrice
                hasVATLiabilityOption
                applicationPrice
                hasPriceOnRequest
                hasPriceToBeDiscussed
                includedInThePurchasePrice
                misc
                hasKnownHiddenDefects
                hiddenDefects
                hasEntriesInLandRegister
                entriesInLandRegister
              }
              rentalSpecifications {
                tenancyLaw
                tenancyLawApplication
                hasCommercialUseAllowance
                hasSubLeasingAllowance
                hasLeasingTerm
                contractValidUntil
                price {
                    rent
                    vat
                }
                valorization {
                  hasValorization
                  vpi
                }
                rentalDeposit {
                  depositMethod
                  depositAmount
                }
                singlePayment {
                    singlePayment
                    singlePaymentInfo
                    singlePaymentAmount
                }
                misc
              }
              commissionBuy {
                commissionCalculationType
                commissionPercentage
                commissionTransfer
                commission
                vat
                additionalNotes
              }
              commissionRent {
                commissionCalculationType
                commissionMonths
                commissionTotal
                commissionPercentageSpecial
                commission
                vat
                additionalNotes
              }
              documents {
                id
                storageUrl
                type
                description
                title
                documentFrom
                state
              }
              neededDocuments {
                landRegister
                landZoning
                developmentPlan
                contaminatedSitesPlan
                cadastralMap
                buildingPermit
                floorPlan
                permitOfUsePlan
                energyPass
                condominiumContract
                usefulValueAppraisal
                advancePayment
                annualStatement
                operatingCosts
                ownersMeetingProtocol
                supplyContracts
                disposalContracts
                insuranceContracts
                inventoryList
                rentalContract
                landRegisterCharges
                pictures
                otherDocs {
                  description
                  docStatus
                }
              }
              furtherConditions {
                contractInOffice
                faggExplained
                faggAndCancelation
                startBeforeCancelation
              }
              dataProcessing {
                generalAgreement
                newsletterAgreed
                questionaireAgreed
                propupAgreed
                headOrganisationAgreed
              }
              additionalEAServices {
                rentControlPackage
                fairRentService {
                  isSelected
                  level
                }
              }
              contractEntityType
              contractEntities {
                customerId
                ownership
                ownershipProof
              }
              businessContractEntity {
                customerId
                ownership
                ownershipProof
              }
              contractEntityTrusteeship {
                trusteeship
                trusteeshipOwner
              }
              agent {
                id
                email
                isRemax
                themeConfig
                firstName
                lastName
                picture
                officeName
                officeAddress
                legalEntity
                phone
              }
            }
          }
        `,
        variables: {
          plainAgreement: data,
        },
      })
      .pipe(
        first(),
        map((result: any) => ({
          ...result.data.plainAgreement,
          lastVisited: new Date(result.data.plainAgreement?.lastVisited)
        }))
      );
  }

  loadPlainAgreement$(objectId: string): Observable<PlainAgreement[]> {
    return this.apollo.query({
      query: gql`
        query GetPlainAgreement($objectId: String!) {
          plainAgreements(objectId: $objectId) {
            id
            objectId
            state
            lastVisited
            contractEntityType
            contractEntities {
              customerId
              title
              firstname
              lastname
              contact {
                email
              }
            }
            businessContractEntity {
              customerId
              customerData {
                id
                companyNumber
                companyRegister
                customers {
                  id
                  title
                  firstname
                  lastname
                  contact {
                    email
                  }
                }
              }
            }
            signatureData {
              signedDocumentUrl
            }
            creator {
              isCreatedByMe
              name
              email
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId,
      },
    }).pipe(first(), map((result: any) => result.data.plainAgreements));

  }

  loadPlainAgreementsByCustomer$(customerId: string): Observable<PlainAgreement[]> {
    return this.apollo.query({
      query: gql`
        query GePlainAgreementsByCustomer($customerId: String!) {
          plainAgreementsByCustomer(customerId: $customerId) {
            id
            objectId
            state
            lastVisited
            contractEntityType
            contractEntities {
              customerId
              title
              firstname
              lastname
              contact {
                email
              }
            }
            businessContractEntity {
              customerId
              customerData {
                id
                companyNumber
                companyRegister
                customers {
                  id
                  title
                  firstname
                  lastname
                  contact {
                    email
                  }
                }
              }
            }
            signatureData {
              signedDocumentUrl
            }
            object {
              objectStatus
              address {
                country
                city
                zipCode
                streetName
                buildingNumber
                doorNumber
                stairway
              }
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        customerId,
      },
    }).pipe(first(), map((result: any) => result.data.plainAgreementsByCustomer));
  }

  loadPlainAgreementDetails$(objectId: string, plainAgreementId: string): Observable<PlainAgreement> {
    return this.apollo.query({
      query: gql`
        query GetPlainAgreementById($objectId: String!, $plainAgreementId: String!) {
          plainAgreement(objectId: $objectId, plainAgreementId: $plainAgreementId) {
            id
            objectId
            state
            lastVisited
            generalData {
              contract
            }
            generalConditions {
              firstPrincipal
              doubleAgent
              jointBusiness
              contractNotes
            }
            propertyDetails {
              property {
                use
                constructionYear
                constructionYearCa
                cadastralCommunity
                entryNumber
                parcelNumber
                parcelNumbers
                movingInDate
                propertyType
                propertyTypeNotes
                shareInOwnership
              }
              rulesOfUse
              roomsAndArea {
                livingArea {
                  area
                  areaCa
                }
                baseArea {
                  area
                  areaCa
                }
                usableArea {
                  area
                  areaCa
                }
              }
              usageBoundCosts {
                givenLater
                costs
                operatingCostsTax
                electricity
                gas
                heating
                telecommunication
                water
                other
                otherDetails
              }
            }
            salesSpecifications {
              sellingPrice
              hasVATLiabilityOption
              applicationPrice
              hasPriceOnRequest
              hasPriceToBeDiscussed
              includedInThePurchasePrice
              misc
              hasKnownHiddenDefects
              hiddenDefects
              hasEntriesInLandRegister
              entriesInLandRegister
            }
            rentalSpecifications {
              tenancyLaw
              tenancyLawApplication
              hasCommercialUseAllowance
              hasSubLeasingAllowance
              hasLeasingTerm
              contractValidUntil
              price {
                rent
                vat
              }
              valorization {
                hasValorization
                vpi
              }
              rentalDeposit {
                depositMethod
                depositAmount
              }
              singlePayment {
                singlePayment
                singlePaymentInfo
                singlePaymentAmount
              }
              misc
            }
            commissionBuy {
              commissionCalculationType
              commissionPercentage
              commissionTransfer
              commission
              vat
              additionalNotes
            }
            commissionRent {
              commissionCalculationType
              commissionTotal
              commissionMonths
              commissionPercentageSpecial
              commission
              vat
              additionalNotes
            }
            documents {
              id
              storageUrl
              type
              description
              title
              documentFrom
              state
            }
            neededDocuments {
              landRegister
              landZoning
              developmentPlan
              contaminatedSitesPlan
              cadastralMap
              buildingPermit
              floorPlan
              permitOfUsePlan
              energyPass
              condominiumContract
              usefulValueAppraisal
              advancePayment
              annualStatement
              operatingCosts
              ownersMeetingProtocol
              supplyContracts
              disposalContracts
              insuranceContracts
              inventoryList
              rentalContract
              landRegisterCharges
              pictures
              otherDocs {
                description
                docStatus
              }
            }
            energyPass {
              energyPassAvailable
              energyPassResponsible
              energyPassDelivery
            }
            furtherConditions {
              contractInOffice
              faggExplained
              faggAndCancelation
              startBeforeCancelation
            }
            dataProcessing {
              generalAgreement
              newsletterAgreed
              questionaireAgreed
              propupAgreed
              headOrganisationAgreed
            }
            additionalEAServices {
              rentControlPackage
              fairRentService {
                isSelected
                level
              }
            }
            contractEntityType
            contractEntities {
              customerId
              birthdate
              isConsumer
              title
              firstname
              lastname
              nationality
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                  longitude
                  latitude
                }
                phone
                email
              }
              pep {
                isPep
                wasPepLastYear
                isFamilyMemberPep
                isKnownToBeCloseToPep
                firstName
                lastName
              }
              IDImages {
                id
                storageUrl
                type
                description
              }
              ownership
              ownershipProof
            }
            businessContractEntity {
              customerId
              ownership
              ownershipProof
              customerData {
                id
                companyNumber
                companyRegister
                contact {
                  address {
                    country
                    city
                    zipCode
                    streetName
                    buildingNumber
                    doorNumber
                    stairway
                  }
                  phone
                  email
                }
                customers {
                  id
                  birthdate
                  firstname
                  lastname
                  nationality
                  contact {
                    address {
                      country
                      city
                      zipCode
                      streetName
                      buildingNumber
                      doorNumber
                      stairway
                      longitude
                      latitude
                    }
                    phone
                    email
                  }
                  pep {
                    isPep
                    wasPepLastYear
                    isFamilyMemberPep
                    isKnownToBeCloseToPep
                    firstName
                    lastName
                  }
                  IDImages {
                    id
                    storageUrl
                    type
                    description
                  }
                }
              }
            }
            contractEntityTrusteeship {
              trusteeship
              trusteeshipOwner
            }
            signatureData {
              signedDocumentUrl
            }
            agent {
              id
              email
              isRemax
              themeConfig
              firstName
              lastName
              picture
              officeName
              officeAddress
              legalEntity
              phone
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId,
        plainAgreementId: plainAgreementId,
      },
    }).pipe(first(), map((result: any) => result.data.plainAgreement));
  }

  deletePlainAgreement$(plainAgreementId: string): Observable<Boolean> {
    return this.apollo.mutate({
      mutation: gql`
        mutation DeletePlainAgreement($plainAgreementId: String!) {
          deletePlainAgreement(plainAgreementId: $plainAgreementId)
        }
      `,
      variables: {
        plainAgreementId,
      },
    }).pipe(
      map((result: any) => result.data.deletePlainAgreement)
    );
  }

  getLastPlainAgreementDetails$(objectId: string): Observable<PlainAgreement> {
    return this.apollo.query({
      query: gql`
        query GetLastPlainAgreement($objectId: String!) {
          lastPlainAgreement(objectId: $objectId) {
            id
            objectId
            lastVisited
            generalData {
              contract
            }
            generalConditions {
              firstPrincipal
              doubleAgent
              jointBusiness
              contractNotes
            }
            energyPass {
              energyPassAvailable
              energyPassResponsible
              energyPassDelivery
            }
            furtherConditions {
              contractInOffice
              faggExplained
              faggAndCancelation
              startBeforeCancelation
            }
            dataProcessing {
              generalAgreement
              newsletterAgreed
              questionaireAgreed
              propupAgreed
              headOrganisationAgreed
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: { objectId },
    }).pipe(first(), map((result: any) => result.data.lastPlainAgreement));
  }

  signAndCompletePlainAgreement$(
    objectId: string,
    plainAgreementId: string,
    contractEntitiesSignatureData: SigneeSignatureResult[] | null,
    agentCompany: AgentCompany
  ): Observable<SignedAndCompletedPlainAgreement> {
    return this.apollo.mutate({
      mutation: gql`
        mutation SignAndCompletePlainAgreement(
          $signatureInput: PlainAgreementSignatureInput!
          $agentCompany: AgentCompany!
        ) {
          signAndCompletePlainAgreement(
            signatureInput: $signatureInput
            agentCompany: $agentCompany
          ) {
            id
            state
            lastVisited
            objectId
            signatureData {
              signedDocumentUrl
            }
          }
        }
      `,
      variables: {
        signatureInput: {
          objectId,
          plainAgreementId,
          contractEntitiesSignatureData: contractEntitiesSignatureData ?
            contractEntitiesSignatureData.map((s) => mapSignatureDataForGraphQL(s)) : null,
        },
        agentCompany,
      },
    })
      .pipe(
        map((result: any) => {
          const untypedPlainAgreement = result.data.signAndCompletePlainAgreement;

          const plainAgreement = untypedPlainAgreement as SignedAndCompletedPlainAgreement;
          plainAgreement.lastVisited = new Date(plainAgreement.lastVisited);

          return plainAgreement;
        })
      );
  }

  createPlainAgreementDocument$(
    objectId: string,
    plainAgreementId: string,
    agentCompany: AgentCompany,
  ): Observable<string> {
    return this.apollo.query({
      query: gql`
        query CreatePlainAgreementDocument(
          $objectId: String!
          $plainAgreementId: String!
          $agentCompany: AgentCompany!
        ) {
          plainAgreementDocument(
            objectId: $objectId
            plainAgreementId: $plainAgreementId
            agentCompany: $agentCompany
          ) {
            url
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId,
        plainAgreementId: plainAgreementId,
        agentCompany: agentCompany,
      },
    }).pipe(
      first(),
      map((result: any) => result.data.plainAgreementDocument.url),
    );
  }

  sendPlainAgreementEmail$(
    objectId: string,
    plainAgreementId: string,
    emailData: any,
    agentCompany: AgentCompany,
  ): Observable<any> {
    return this.apollo.query({
      query: gql`
        query TriggerSendPlainAgreementEmailQuery(
          $objectId: String!
          $plainAgreementId: String!
          $emailData: EmailData!
          $agentCompany: AgentCompany!
        ) {
          plainAgreementSendEmail(
            objectId: $objectId
            plainAgreementId: $plainAgreementId
            emailData: $emailData
            agentCompany: $agentCompany
          )
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        objectId,
        plainAgreementId,
        emailData,
        agentCompany,
      },
    }).pipe(
      first(),
      map((result: ApolloQueryResult<any>) => {
        const success = result.data?.plainAgreementSendEmail;

        if (!success) {
          throw new Error();
        }

        return true;
      }),
    );
  }

  getPlainAgreementEmailVariables$(
    objectId: string,
    plainAgreementId: string,
    documentUrl: string,
  ): Observable<any> {
    return this.apollo.query({
      query: gql`
        query GetPlainAgreementEmailVariables(
          $objectId: String!
          $plainAgreementId: String!
          $documentUrl: String!
        ) {
          plainAgreementEmailVariables(
            objectId: $objectId
            plainAgreementId: $plainAgreementId
            documentUrl: $documentUrl
          ) {
            propertyAddress
            documentUrl
            signature
          }
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        objectId,
        plainAgreementId,
        documentUrl,
      },
    }).pipe(
      first(),
      map((result: ApolloQueryResult<any>) => result.data?.plainAgreementEmailVariables),
    );
  }

  compressFiles$(objectId: string, plainAgreementId: string, filePaths: string[], filenames: string[]): Observable<string> {
    return this.apollo.query({
      query: gql`
        query CompressPlainAgreementFiles(
          $objectId: String!
          $plainAgreementId: String!
          $filePaths: [String!]!
          $filenames: [String!]!
        ) {
          compressPlainAgreementFiles(
            objectId: $objectId
            plainAgreementId: $plainAgreementId
            filePaths: $filePaths
            filenames: $filenames
          )
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId,
        plainAgreementId: plainAgreementId,
        filePaths: filePaths,
        filenames: filenames,
      },
    }).pipe(
      first(),
      map((result: any) => result.data.compressPlainAgreementFiles),
    );
  }
}


